import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Governance from './pages/governance';
import NavBar from './navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import Footer from './components/footer'
import Development from './pages/development';
import Support from './pages/support';
import Contactus from './pages/contactus';
import { history } from './history';
class App extends Component {
  render() {

    return (
      <div className="App">

        <Router history={history}>
          <NavBar />
          <Routes><>
            <Route path="" element={<Home />} />
            <Route path="/governance" element={<Governance />} />
            <Route path="/development" element={<Development />} />
            <Route path="/support" element={<Support />} />
            <Route path="/contactus" element={<Contactus />} />
          </></Routes>
        </Router>
        <Footer />
      </div >

    );
  }
}

export default App;
