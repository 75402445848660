import React from 'react'
import Container from 'react-bootstrap/Container'
import Logo from '../images/logo2.png'
import ALM from '../images/ALM.png'
import Monitor from '../images/Monitor.png'
import Strat from '../images/Strategy.jpg'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Button from 'react-bootstrap/Button'

function Governance() {
    return (
        <>
            <div className="gov-image">
                <img src={Logo} alt='Azion' style={{ "width": "50%", "minWidth": "250px" }} /><br />
                <h2>POWER PLATFORM SPECIALISTS</h2>
            </div>
            <Container fluid className='p-0'>

                <div className="intro">
                    <h3>Governance</h3>
                    <p>We offer strategic advice and guidance on leveraging Microsoft cloud products to achieve your business goals.</p>
                    <p>Our services include evaluating your current processes, identifying opportunities for improvement, and developing comprehensive environmental strategies.</p><p> Additionally, we provide expert Application Lifecycle Management (ALM) guidance to ensure seamless implementation and long-term success
                    </p><br />
                </div>

                <div className="row">
                    <div className="column">
                        <div className='imgDiv'><img alt='Strategy' src={Strat} class="home" style={{ "background-color": "#ffffff" }} /></div> <div className='contentDiv' ><h3>Environmental Strategy</h3>
                            <p>Every organisation has a unique set of requirements and constraints
                                that need to be considered when implementing a governance framework.
                                We work with you to understand your organisation's needs and constraints
                                and develop a governance framework that is tailored to your organisation.
                                We can help you with the following:</p>
                            <div >
                                <ul>
                                    <li>Data loss prevention policies.</li>
                                    <li>Managing environment creations and allocations.</li>
                                    <li>Security and compliance.</li>
                                </ul>
                            </div>
                            <b><i>Read more:</i></b><br />
                            <Button variant="btn btn-secondary m-1" target='_blank' href="https://learn.microsoft.com/en-us/power-platform/guidance/white-papers/environment-strategy">Microsoft <AiOutlineArrowRight /></Button>
                            <Button variant="btn btn-secondary m-1" target='_blank' href="https://learn.microsoft.com/en-us/power-platform/guidance/adoption/dlp-strategy">DLP Policies <AiOutlineArrowRight /></Button>
                        </div>
                    </div>
                    <div className="column alternate">
                        <div className='imgDiv'><img alt='ALM' src={ALM} class="home" /></div> <div className='contentDiv' ><h3>Application Lifecycle Management (ALM)</h3>
                            <p>Application Lifecycle Management (ALM) encompasses the oversight, management, and administration of software development from initial planning to eventual retirement.</p><p>It includes every stage of the process, such as requirements management, software architecture, programming, testing, maintenance, change management, continuous integration, and project management.</p><p> The goal of ALM is to enhance software quality, accelerate time to market, and efficiently manage the entire application lifecycle.</p>
                        </div></div>
                    <div className="column">
                        <div className='imgDiv'><img alt='COE Toolkit' src={Monitor} class="home" /></div><div className='contentDiv'><h3>Monitoring and Control (COE Toolkit)</h3>
                            <p>We assist in implementing the Center of Excellence (CoE) Toolkit to enhance monitoring and governance of your Power Platform environment.</p><p>The CoE Toolkit provides powerful tools for overseeing your platform's usage, performance, and compliance. Beyond monitoring, it also supports the establishment of robust governance processes, ensuring that your organization can effectively manage, scale, and optimize its Power Platform solutions. </p>
                            <b><i>Read more:</i></b><br />
                            <Button variant="btn btn-secondary m-1" target='_blank' href="https://learn.microsoft.com/en-us/power-platform/guidance/coe/starter-kit">COE Toolkit <AiOutlineArrowRight /></Button>

                        </div></div>
                </div>

            </Container >
        </>
    );
}

export default Governance;

