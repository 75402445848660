import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from './history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'f5060cf5-e976-424a-9441-c02cfc137c54',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {

            [reactPlugin.identifier]: { history: history }

        }
    }
});
appInsights.loadAppInsights();

export { appInsights, reactPlugin };