import React from 'react';
import Logo from '../images/logo2.png';


const Header = () => (

    <>
        <div className="homepage-image">
            <img src={Logo} alt='Azion' style={{ "width": "50%", "minWidth": "250px" }} /><br />
            <h2>POWER PLATFORM SPECIALISTS</h2>
        </div>

    </>

)


export default Header;