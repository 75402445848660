import React from "react";
import logo from '../images/logoSmall.png'

const Footer = () => (

    <>
        <div className="fixed-bottom bg-azdark" style={{ "height": "50px", "paddingBottom": "5px", "color": "#fff", "vertical-align": "middle" }}>
            <div className="row ">
                <div className="col pt-1">
                    <img
                        src={logo}
                        style={{ "maxWidth": "150px" }}
                        alt="AZION Logo"
                    />

                </div>
                <div className="col">
                    <div className="footerMob">MOBILE: 0410 019 268<br />ABN: 83 271 227 051</div>

                </div>
            </div>
        </div>
    </>
)

export default Footer;