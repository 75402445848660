import React from "react";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import logo from './images/logoSmall.png'
import { CgMenuGridO } from 'react-icons/cg'


const AZNavBar = () => (

    <>
        <Navbar expand="lg" className="fixed-top bg-azdark">
            <Container >
                <Navbar.Brand href="/"><img
                    src={logo}
                    style={{ "maxWidth": "150px" }}
                    alt="AZION Logo"
                /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ "color": "#eee" }} >
                    <CgMenuGridO size={20} className="b-0" />
                </Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="justify-content-end" id="basic-navbar-nav">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown title="Services">
                            <NavDropdown.Item href="/governance">Governance</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/development">Development & Integration</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/support">Support & Training</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/contactus">Contact us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>
)

export default AZNavBar;