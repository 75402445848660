import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appInsights';


const rootelement = document.getElementById('root');
const root = createRoot(rootelement);

root.render(
  <StrictMode >
    <AppInsightsErrorBoundary onError={(error) => console.log(error)} appInsights={reactPlugin}>

      <App />
    </AppInsightsErrorBoundary>,
  </StrictMode >

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
